<template>
  <div class="new-program">
    <div v-if="form">
      <v-row>
        <v-col sm="8">
          <div class="d-flex justify-space-between align-center mb-4">
            <h2 class="mr-auto">Preview</h2>
            <v-btn
              class="primary--text mr-5"
              text
              depressed
              :loading="form.$busy"
              @click="saveForLater"
            >
              Save & Finish Later
            </v-btn>

            <v-btn
              class="bg-primary-gradient primary next-button px-12"
              @click="saveAndFinish"
              :loading="form.$busy"
              text
              depressed
            >
              Done
              <v-icon> {{ icons.next }} </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="8">
          <v-card class="mx-auto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ selectedData.body_type | readableEnums(bodyTypes) }}
                </v-list-item-title>
                <div class="mb-4">{{ selectedData.description }}</div>
                <div class="subtitle-1">
                  Calories: <b>{{ selectedData.calories }}</b>
                </div>
                <div class="subtitle-1">
                  Template Name or Restriction:
                  <b>{{ selectedData.restriction_code }}</b>
                </div>
                <div class="subtitle-1">
                  Template Number or Diff Meals:
                  <b>{{ selectedData.template_number }}</b>
                </div>
                <div class="subtitle-1">
                  Total Calories:
                  <b>{{ totalCalories }}</b>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-card-title>Meal Guide</v-card-title>

            <div class="methods-list--container">
              <v-list class="transparent" v-if="meals.length > 0">
                <v-list-item v-for="(item, index) in meals" :key="index">
                  <v-list-item-title class="custom-item">
                    <b>
                      {{ item.meal_type | readableEnums(mealTypes) }}
                    </b>
                    <br />
                    <v-row v-if="item.meal_type === 4 || item.meal_type === 5">
                      <v-col v-if="item.food && item.food.cloudinary_image">
                        <v-img
                          :src="item.food.cloudinary_image.medium"
                          alt="item.food.title"
                          width="70"
                        />
                      </v-col>
                      <v-col cols="7">
                        <span v-if="item.food">{{ item.food.title }}</span>
                        <span v-else>{{ item.food_name }}</span>
                      </v-col>
                      <v-col>{{ item.serving_quantity }}</v-col>
                      <v-col>{{ item.serving_unit }}</v-col>
                      <v-col>
                        <span v-if="item.food" class="font-italic">
                          Calories: <b>{{ item.food.calories }}</b>
                        </span>
                      </v-col>
                    </v-row>
                    <div v-else>
                      <span v-if="item.food">{{ item.food.title }}</span>
                      <span v-else>{{ item.food_name }}</span>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>

              <div
                v-else
                class="d-flex justify-center align-center text-center py-12"
              >
                <div>
                  <img
                    class="pa-0"
                    width="400px"
                    src="@/assets/images/no-lists.png"
                    alt=""
                  />
                  <h1 class="display-1 grey--text no-workouts-list">
                    No Meal Guides
                  </h1>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-toast ref="toast" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VToast from '@/components/elements/Toast'
import Event from '@/services/eventBus'
import { mdiArrowRight } from '@mdi/js'
import Form from '@/utils/form'
import { pick, values, flattenDeep } from 'lodash' // isNull

export default {
  name: 'RecipeDetailsTab',

  components: { VToast },

  data() {
    return {
      icons: {
        next: mdiArrowRight,
      },
      form: new Form({}),

      meals: [],
      totalCalories: 0,
    }
  },

  computed: {
    ...mapState({
      mealTypes: (state) => state.meals.extra.mealTypes,
      bodyTypes: (state) => state.meals.extra.bodyTypes,
      selectedData: (state) => state.meals.selectedData,
    }),
  },

  methods: {
    ...mapActions({
      getSingleData: 'meals/getSingleData',
      getMeals: 'meals/getMeals',
      saveData: 'meals/saveData',
    }),

    async fetchData(id) {
      await this.getSingleData({ id })
      this.fillForm(this.selectedData)

      await this.getMeals({ id, mealTime: 1 })

      this.meals = this.selectedData.meals
      this.meals.forEach((i) => {
        if (i.food) {
          this.totalCalories += i.food.calories
        }
      })
    },

    saveForLater() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      delete payload.image

      this.saveData(payload)
        .then((data) => {
          Event.$emit(
            data.completed_at
              ? 'update-active-meals-list'
              : 'update-draft-meals-list'
          )

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({
              name: data.completed_at ? 'active.meals' : 'draft.meals',
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
              this.$refs.toast.open({
                color: 'red',
                message: flattenDeep(values(err.response.data.errors)),
              })
            }
          })
        })
    },

    saveAndFinish() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      payload.completed = true
      delete payload.image

      this.saveData(payload)
        .then((data) => {
          Event.$emit('update-active-meals-list')

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({
              name: 'active.meals',
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
              this.$refs.toast.open({
                color: 'red',
                message: flattenDeep(values(err.response.data.errors))[0],
              })
            }

            this.form.$busy = false
          })
        })
    },

    fillForm(data) {
      data = pick(data, [
        'id',
        'body_type',
        'restriction_code',
        'template_number',
        'calories',
      ])

      this.form = new Form(data)
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },
}
</script>
<style>
.v-list-item__title.custom-item {
  text-overflow: inherit;
  white-space: normal;
}
</style>
